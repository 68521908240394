import React from 'react';
import "./banner.css";

function Banner() {
    return <section id='banner'>
        <div className='container'>
            <div className='row'>
                <p />
                <div className='col-lg-6'>
                    <h2 className='lblFlamaph'>ALUMÍNIO E ESQUADRIAS</h2>
                    <p></p>
                    <h6 className='lblFrase'>Somos apaixonados por esquadrinhar seus sonhos</h6>

                    <div className='container'>
                        <a href="/app" className="btn btn-lg btn-banner">ALUMÍNIO</a>
                        <a href="/" className="btn btn-lg btn-banner">ESQUADRIAS</a>
                    </div>

                </div>
                <div className='col-lg-6'>
                    <h2 className='lblObra'>OBRAS:</h2>
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="4000">
                                <a href="/#"><img src="03.png" className='tres' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='04.png' className='quatro' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='05.png' className='cinco' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='06.png' className='seis' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section >;
}

export default Banner;