import "./menu.css";

function Menu() {
    return <div className="menu">
        <ul className="social">
            <li className="list-inline-item"><h6 className="txtCNPJ">CNPJ: 26.664.365/0001-04</h6></li>
            <li className="list-inline-item"><h6 className="txtFone">Telefone : (11) 3607-1808</h6></li>
            <li className="list-inline-item"><h6 className="txtTexto">Siga : </h6></li>
            <li className="list-inline-item"><a href="/#"><i className="fa fa-facebook"></i></a></li>
            <li className="list-inline-item"><a href="/#"><i className="fa fa-twitter"></i></a></li>
            <li className="list-inline-item"><a href="/#"><i className="fa fa-instagram"></i></a></li>
        </ul>
    </div>;
}
export default Menu;