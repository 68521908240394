import React from 'react';
import "./obras.css";


function Obras() {
    return <section id="preco">
        <div className="container">
            <div className="row text-center">
                <div className="titulo">
                    <h2>Nossos produtos</h2>
                    <p>Qualidade</p>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Esquadrias entre vãos</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Colocação em um dia</p>
                            <img src="esquadrias_vao.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Fachadas</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Mostre o projeto e deixe conosco</p>
                            <img src="fachadas.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Gradis e corrimãos</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Mostre o projeto e deixe conosco</p>
                            <img src="gradis.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Coberturas</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Mostre o projeto e deixe conosco</p>
                            <img src="coberturas.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Revestimento metálico</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Mostre o projeto e deixe conosco</p>
                            <img src="revestimento.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Personalizados</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Mostre o projeto e deixe conosco</p>
                            <img src="personalizados.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>;
}

export default Obras;
