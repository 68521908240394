import "./navbar.css";
import imgfundo from "../../assets/energia03.png";


function Navbar() {
    return <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
            <a className="navbar-brand" href="index.html">
                <img src="logo_transp.png" alt="flamaph" height="90" />
            </a>

            <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#pontos">Quem Somos</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/#">Obras e Projetos</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/#">Contato</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>;
}



/*
function Navbar() {
    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">
        <div className='container-fluid'>
            <a className="navbar-brand" href="/#index.html">
                <img src="logo_transp.png" alt="flamaph" height="75" />
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">

                    </li>
                    <li className="nav-item">

                    </li>
                </ul>
            </div>
        </div>
    </nav>;


    
    return <section className="banner">

        <div className="row">

            <div className="col-lg-6">
                <img src={imgfundo} alt="SollarNow" />
            </div>

            <div className="col-lg-6 text-center">
                <br />
                <h1>Uma maneira consciente para preservar o ecosistema e economizar.</h1>
                <br />
                <h4>Gerencie seu consumo de qualquer lugar.</h4>
                <br />
                <br />
                <a href="#" className="btn btn-dark btn-lg btn-banner">Fazer um orçamento</a>
                <a href="#" className="btn btn-outline-light btn-lg btn-banner">Já sou cliente</a>
            </div>

        </div>
       </section>;

}
       */

export default Navbar;