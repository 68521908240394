import React from 'react';
import "./footer.css";
function Footer() {
    var ano = new Date().getFullYear();

    return <section id="footer">
        <div className="container">
            <p />
            <p />
            <p />
            <p />
            <p />
            <p />
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://www.google.com/maps/search/rua+Arminda+Beranger,+582%2F594+%E2%80%93+Vila+Isabel+%E2%80%93+Osasco+%E2%80%93+SP+-+6180-130/@-23.5373777,-46.8053306,17z/data=!3m1!4b1?authuser=0&entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D"><i className="fa fa-regular fa-map fa-x" id="map"></i></a></li>
                <li className="list-inline-item"><a href="https://www.facebook.com/flamaph"><i className="fa fa-facebook fa-2x" id="face"></i></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/flamaph"><i className="fa fa-instagram fa-2x" id="insta"></i></a></li>
                <li className="list-inline-item"><a href="mailto:contato@flamaph.com.br"><i className="fa fa-envelope fa-2x" id="mail"></i></a></li>
                <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5511953505609"><i className="fa fa-whatsapp fa-2x" id="zap"></i></a></li>
            </ul>
            <p />
            <p />
            <p />
            <p />
        </div>
        <div>
            <h6>Rua Arminda Beranger, 582/ 594 - Vila Isabel - Osasco - SP - CEP 6180-130</h6>
            <p />
            <p />
        </div>
        <a className="h6" href="mailto:contato@flamaph.com.br">contato@flamaph.com.br</a>
        <p />
        <p />
        <p />
        <p />
        <p />
        <p />
        <p><img src="ssl_cartao-remover.png" alt="" height="120" />
        </p>
        <p className="h6">© Desenvolvido por FLAMAPH SCIENCE - Grupo Flamaph - {ano}</p>

    </section>
}

export default Footer;