import Navbar from "../../components/navbar/navbar.jsx";
import Footer from "../../components/footer/footer.jsx";
import Banner from "../../components/banner/banner.jsx";
import Menu from "../../components/menu/menu.jsx";
import Features from "../../components/features/features.jsx";
import Obras from "../../components/obras/obras.jsx";

function Home() {
    return <>
        <Menu />
        <Navbar />
        <Banner />
        <Features />
        <Obras />
        <Footer />
    </>
}

export default Home;