import React from 'react';

import "./features.css";

function Features() {
    return <section id="features">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-heart fa-4x"></i>
                    <h4>Fidelidade e dedicação</h4>
                    <p>Apaixonados por fazer seu projeto uma realidade.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-globe-americas fa-4x"></i>
                    <h4>Atendimento</h4>
                    <p>Melhor equipe da américa latina.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-columns fa-4x"></i>
                    <h4>Instalação rápida</h4>
                    <p>Estamos sempre nos atualizando e trabalhando com as melhores técnicas.</p>
                </div>

            </div>
        </div>
    </section>;
}

export default Features;
